import React, { Component } from "react";
import Joi from "joi-browser";
import Input from "./common/input";
import ContactService from "../api/contactService";

const businessId = "636d34ff1a636407ab11c99f";

const complexityOptions = {
  min: 10,
  max: 14,
  numeric: 1,
  requirementCount: 3,
};
class ContactForm extends Component {
  state = {
    form: {
      name: "",
      email: "",
      phone: "",
      service: "",
      details: "",
      businessId: businessId,
    },
    errors: {},
  };
  schema = {
    name: Joi.string().max(50).required().label("Name"),
    email: Joi.string().email().required().label("Email"),
    phone: Joi.string().length(10).required().label("Phone"),
    service: Joi.string().required().label("Service"),
    details: Joi.string().max(255).required().label("Project Details"),
    businessId: Joi.string().required(),
  };
  validate = () => {
    const options = { abortEarly: false };
    const { error } = Joi.validate(this.state.form, this.schema, options);
    if (!error) return null;

    const errors = {};
    error.details.map((i) => (errors[i.path[0]] = i.message));
  };
  validateProperty = ({ name, value }) => {
    const obj = { [name]: value };
    const schema = { [name]: this.schema[name] };
    const { error } = Joi.validate(obj, schema);

    return error ? error.details[0].message : null;
  };
  handleSubmit = async (e) => {
    e.preventDefault();

    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) return;
    this.validate();
    // const result = await ContactService.createContact(this.state.form);
    console.dir(e);
    // alert(result);
  };
  handleChange = ({ currentTarget: input }) => {
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input);
    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];

    const form = { ...this.state.form };
    form[input.name] = input.value;
    this.setState({ form, errors });
  };
  render() {
    const { name, email, phone, service, details } = this.state.form;
    const { errors } = this.state;
    return (
      <div className="flex-grow justify-center bg-white px-8 lg:px-20 py-20 md:h-3/4">
        <div className="flex justify-center">
          <div>
            <h2 className="text-xl lg:text-xl font-medium mb-1 text-gray-700">
              Have a Question?
            </h2>
            <span className="text-sm mb-4 text-gray-400">
              Pop us a message and we'll get in touch.
            </span>
            {/* <!-- Form --> */}
            <form
              name="contact"
              data-netlify="true"
              autoComplete="off"
              netlify-honeypot="bot-field"
              onSubmit={this.handleSubmit}
            >
              <p className="hidden">
                <label>
                  Don’t fill this out if you’re human:{" "}
                  <input name="bot-field" />
                </label>
              </p>
              <Input
                value={name}
                onChange={this.handleChange}
                type="text"
                id="name"
                name="name"
                label="Name"
                error={errors.name}
              />
              <Input
                type="email"
                id="email"
                name="email"
                label="Email"
                value={email}
                onChange={this.handleChange}
                placeholder="the placeholder"
                error={errors.email}
              />
              <Input
                type="text"
                id="phone"
                name="phone"
                value={phone}
                onChange={this.handleChange}
                label="Phone"
                error={errors.phone}
              />
              {/* <div className="input-animated">
                <input autoFocus placeholder="the placeholder" />
                <label htmlFor="name" className="label-name">
                  <span className="content-name">Name</span>
                </label>
              </div> */}
              {/* <div className="input-animated">
                <input />
                <label htmlFor="email" className="label-email">
                  <span className="content-email">Email</span>
                </label>
              </div> */}
              {/* <div className="input-animated">
                <input
                 
                  placeholder="the placeholder"
                />
                <label htmlFor="phone" className="label-name">
                  <span className="content-name">Phone Number</span>
                </label>
              </div> */}
              <label htmlFor="service" className="sr-only">
                Underline select
              </label>
              <select
                onChange={this.handleChange}
                value={service}
                name="service"
                id="service"
                className="block py-2.5 px-0 w-full text-sm text-gray-500 bg-transparent border-0 border-b-2 border-gray-200 appearance-none dark:text-gray-400 dark:border-gray-700 focus:outline-none focus:ring-0 focus:border-gray-200 peer"
              >
                <option className="selected" value={""}>
                  Choose a Service
                </option>
                <option value="tree-removal">Tree Removal</option>
                <option value="tree-pruning">Tree Pruning</option>
                <option value="stump-removal">Stump Removal</option>
                <option value="tree-cutting">Tree Cutting</option>
                <option value="tree-trimming">Tree Trimming</option>
              </select>
              {/* 
              <Input
                type="text"
                id="service"
                name="service"
                value={service}
                onChange={this.handleChange}
                required
                label="Service"
                error={errors.service}
              /> */}
              {/* <div className="input-animated">
                <input placeholder="services" />
                <label htmlFor="service" className="label-name">
                  <span className="content-name">Service</span>
                </label>
              </div> */}
              <div>
                <textarea
                  name="details"
                  value={details}
                  onChange={this.handleChange}
                  placeholder="Tell us About your Project"
                  id="question"
                  cols="30"
                  rows="5"
                  minLength="10"
                  maxLength="100"
                  required
                ></textarea>
              </div>
              <button
                type="submit"
                id="submit-button"
                disabled={this.validate()}
                onSubmit={this.handleSubmit}
                className="w-full font-semibold rounded-md uppercase text-center px-5 py-3 shadow-md my-4 lg:mb-0 bg-gradient-to-r from-blue-500 to-green-400 text-green-50 focus:ring-4 focus:ring-blue-300 hover:from-blue-400 hover:to-green-300"
              >
                Get Your Free Quote
              </button>
              <span
                id="success"
                className="hidden absolute mt-2 px-4 py-2 font-medium border rounded-sm border-green-500 bg-green-100 text-green-600"
              >
                Thanks! We'll be in touch soon.
              </span>
              <span
                id="error"
                className="hidden absolute mt-2 px-4 py-2 font-medium border rounded-sm border-red-500 bg-red-100 text-red-600"
              >
                Whoops... Something went wrong.
              </span>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default ContactForm;
