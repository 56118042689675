import React, { Component } from "react";
import { Link } from "react-router-dom";

class AboutPage extends Component {
  state = {};
  render() {
    const { city, state, initial } = this.props;
    return (
      <>
        {/* <!-- Start of 'About Us' Landing --> */}
        <section className="about_us_landing pt-32 px-12 pl-6 pb-24 lg:pt-44 lg:pb-32 lg:pl-32">
          <h1 className="text-xs lg:text-base uppercase font-bold mb-3 lg:mb-6 text-gray-200">
            About Us
          </h1>
          <p className="font-semibold md:w-2/3 xl:w-1/2 text-lg md:text-2xl lg:text-3xl max-w-prose text-gray-200">
            Leading Tree Service Contractors in Columbia, MO
          </p>
        </section>

        {/* <!-- Start of 'About Us' longer description/story --> */}
        <section className="flex flex-col md:items-center pt-16 lg:pt-24">
          <h2 className="px-8 tracking-wider font-bold text-3xl lg:text-4xl uppercase text-gray-700">
            Our Philosophy
          </h2>
          <p className="max-w-prose px-8 py-12 lg:py-16 lg:px-0 text-gray-700">
            We put our customers first, and if you're seeking a professional and
            reliable Tree Service contractor with experience, then you are in
            the correct place.
            <br />
            <br />
            We are formally trained in all matters related to stump grinding,
            tree removal, tree pruning etc.. and have a commitment to doing them
            extremely well.
            <br />
            <br />
            We offer competivie pricing but our core values are about the
            customer experience and making sure first our clients are happy with
            our work.
            <br />
            <br />
            This is our commitment to the city of
            <span className="text-sm font-semibold text-gray-600">
              {" "}
              Columbia
            </span>
          </p>
        </section>

        {/* <!-- Start of 'Ready to get started' Section --> */}
        <section className="pt-24 pb-32 lg:pt-32 lg:pb-40 mx-8 lg:mx-0">
          {/* <!-- Card --> */}
          <div className="relative flex justify-center">
            <div className="cust_offset-border relative p-20 text-center border-4 border-gray-700">
              <h2 className="text-3xl font-bold mb-14 text-gray-900">
                Ready to Get Started?
              </h2>
              <Link
                className="inline-block uppercase text-center cursor-pointer font-semibold px-5 py-2 lg:px-10 border bg-gray-800 text-gray-50 mx-2 lg:mx-8 transition-all duration-300 shadow-sm hover:bg-gray-900 hover:shadow-md focus:ring-2 focus:ring-blue-300"
                to="/contact"
              >
                Get Free Quote
              </Link>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default AboutPage;
