import "axios";
import axios from "axios";
import { API_URL } from "../Constants";

class ContactService {
  async createContact(contact) {
    console.log("executed service");
    console.log(contact);
    return await axios({
      method: "post",
      url: API_URL,
      data: JSON.stringify(contact),
      headers: {
        "access-control-allow-origin": "*",
        "Content-Type": "application/json",
        "x-auth-token":
          "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2MzcwMGRiZWViZDJjYTEyYTllYzA4Y2QiLCJpc0FkbWluIjp0cnVlLCJpYXQiOjE2NjgyODc5MzR9.6-8jxDOeeuNXikGYyDAv7fQhVR10_7dIcs70rUmvK0M",
      },
    });
    // return await axios.post(`${LOCAL_API_URL}/api/contacts`, contact, {
    //   headers: {
    //     "x-auth-token":
    //       "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2MzZlYzJmMTE5YjIzOTg1YmY2YTAyYjYiLCJpc0FkbWluIjpmYWxzZSwiaWF0IjoxNjY4MjAzMjUwfQ.uSEU8WHf387eXhg1VvXnHKqAGh98_IPMvDyYGe_r0n0",
    //   },
    // });
  }
}

export default new ContactService();
