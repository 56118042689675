import React, { Component } from "react";

class ServicePage extends Component {
  state = {};
  render() {
    return (
      <>
        {/* <!-- Start of 'Our Work' Landing --> */}
        <section className="flex px-8 lg:px-0 justify-center bg-gray-100">
          <div className="flex flex-col lg:flex-row items-center max-w-screen-xl lg:px-8">
            <div className="pt-36 pb-20 lg:pt-32 lg:pb-20 lg:pr-16 ">
              <h1 className="text-xs lg:text-base uppercase font-bold mb-2 lg:mb-4 text-gray-400">
                Tree Services
              </h1>
              <h2 className="font-semibold text-3xl lg:text-5xl text-gray-700 mb-8">
                How We Can Help You
              </h2>
              <p className="text-lg lg:text-xl max-w-prose leading-relaxed text-gray-600">
                We understand that your time is valuable, and we want this
                process to be as smooth and easy as possible!
              </p>
            </div>
            {/* <!-- Services Overlapping Image --> */}
            <div className="lg:pt-28 -mb-12 lg:-mb-16 w-full lg:w-1/2">
              <img
                className=" max-h-96 mx-auto object-cover shadow-lg "
                src={require("../images/axe-in-stump.jpg")}
                alt="An ecstatic man."
              />
            </div>
          </div>
        </section>
        {/* <!-- Start of Previous Work section --> */}
        <section className="lg:pt-24 pb-40 lg:pb-40 mt-24">
          <h2 className="text-center text-3xl lg:text-4xl font-semibold uppercase text-gray-900 mb-20 lg:mb-24">
            Tree Services
          </h2>

          {/* <!-- project 1 --> */}
          <div className="flex flex-col items-center mx-4 md:w-2/3 md:mx-auto lg:w-full lg:mx-0">
            <div className="lg:flex items-center justify-center gap-x-14">
              {/* <!-- decription --> */}
              <div className="lg:w-1/4">
                <h2 className="inline-block text-xl font-semibold uppercase text-gray-800">
                  Tree Removal
                </h2>
                <p className="leading-relaxed my-6 text-gray-700">
                  Popular opinion suggest that tree removal has to be a
                  difficult process and the last thing anyone should have to do.
                  Tree removal reasons can range from unblocking your view,tree
                  is damaged/diseased, lawn surrounding your tree is
                  clay/dirt/moss etc. These are all valid reasons to call
                  <a href="#">Elizabethtown Tree Services</a> and have your tree
                  removed.
                </p>
                {/* <a
                  className="inline-block uppercase text-center text-sm font-bold px-5 py-3 lg:px-10 lg:py-4 rounded-md bg-blue-700 text-yellow-50 hover:bg-gray-700 focus:ring-2 focus:ring-yellow-300"
                  href="/project-url.html"
                >
                  View More
                </a> */}
              </div>
              {/* <!-- Image --> */}
              <div className="relative lg:rounded-xl h-80 lg:w-80 mt-8 lg:mt-0 -mx-4 lg:mx-0 overflow-hidden">
                <div className="absolute top-0 right-0 left-0 bottom-0 opacity-30 bg-blue-600"></div>
                <img
                  className="h-full w-full object-cover"
                  src={require("../images/sitting-on-tree-removal.jpg")}
                  alt=""
                />
              </div>
            </div>
          </div>

          {/* <!-- project 2 --> */}
          <div className="flex flex-col items-center my-24 lg:my-40 mx-4 md:w-2/3 md:mx-auto lg:w-full lg:mx-0">
            <div className="lg:flex flex-row-reverse items-center justify-center gap-x-14">
              {/* <!-- decription --> */}
              <div className="lg:w-1/4">
                <h2 className="inline-block text-xl font-semibold uppercase text-gray-800">
                  Tree Pruning
                </h2>
                <p className="leading-relaxed my-6 text-gray-700">
                  The opposite of tree removal is tree pruning, which encourages
                  the tree growth and maintains the overall structure of the
                  tree. Maintaining tree health with tree pruning mitigates the
                  risk for broken limbs, tree branches falling, or the entire
                  tree growing correctly.
                  <span>
                    <a href="#"> Elizabethtown Tree Services </a>
                  </span>
                  offers opportunies all year round services for tree pruning,
                  due to the most benefecial time to prune is the fall or winter
                  while the tree is dormant.
                </p>
                {/* <a
                  className="inline-block uppercase text-center text-sm font-bold px-5 py-3 lg:px-10 lg:py-4 rounded-md bg-blue-700 text-yellow-50 hover:bg-gray-700 focus:ring-2 focus:ring-yellow-300"
                  href="/project-url.html"
                >
                  View More
                </a> */}
              </div>
              {/* <!-- Image --> */}
              <div className="relative lg:rounded-xl h-80 lg:w-80 mt-8 lg:mt-0 -mx-4 lg:mx-0 lg:rounded-tr-10xl lg:rounded-bl-10xl overflow-hidden">
                <div className="absolute top-0 right-0 left-0 bottom-0 opacity-30 bg-blue-600"></div>
                <img
                  className="h-full w-full object-cover"
                  src={require("../images/tree-pruning.jpg")}
                  alt=""
                />
              </div>
            </div>
          </div>

          {/* <!-- project 3 --> */}
          <div className="flex flex-col items-center mx-4 md:w-2/3 md:mx-auto lg:w-full lg:mx-0">
            <div className="lg:flex items-center justify-center gap-x-14">
              {/* <!-- decription --> */}
              <div className="lg:w-1/4">
                <h2 className="inline-block text-xl font-semibold uppercase text-gray-800">
                  Stump Removal
                </h2>
                <p className="leading-relaxed my-6 text-gray-700">
                  Stumps are unsightly, hazardous, and attraction for insects if
                  your stump is dying.
                  <span>
                    <a href="#"> Stump removal </a>
                  </span>
                  removes the habitat for pest or insects and why we offer the
                  service.
                </p>
                {/* <a
                  className="inline-block uppercase text-center text-sm font-bold px-5 py-3 lg:px-10 lg:py-4 rounded-md bg-blue-700 text-yellow-50 hover:bg-gray-700 focus:ring-2 focus:ring-yellow-300"
                  href="/project-url.html"
                >
                  View More
                </a> */}
              </div>
              {/* <!-- Image --> */}
              <div className="relative lg:rounded-xl h-80 lg:w-80 mt-8 lg:mt-0 -mx-4 lg:mx-0 overflow-hidden">
                <div className="absolute top-0 right-0 left-0 bottom-0 opacity-30 bg-blue-600"></div>
                <img
                  className="h-full w-full object-cover"
                  src={require("../images/cutting-tree.jpg")}
                  alt=""
                />
              </div>
            </div>
          </div>
        </section>

        {/* <!-- Start of CTA --> */}
        <section className="relative bg-gradient-to-r from-blue-500 to-green-400  px-4 sm:px-8 lg:px-16 xl:px-40 2xl:px-64 py-8">
          <div className="flex flex-col md:flex-row items-center justify-between">
            <h2 className="text-xl font-bold text-white">
              Get in touch at <a href="tel:+15025097053">(573) 555-8787</a>
            </h2>
            <a
              href="/contact.html"
              className="px-8 py-4 mt-8 lg:mt-0 uppercase rounded inline-block font-bold lg:ml-8 bg-blue-50 text-blue-600 hover:bg-blue-600 hover:text-blue-50"
            >
              Contact Us
            </a>
          </div>
        </section>
        {/* <!-- end cta --> */}
      </>
    );
  }
}

export default ServicePage;
