import React, { Component } from "react";
import "./index.css";
import "./App.css";
import LandingPage from "./components/landingPage";
import Navigation from "./components/navigation";
import Footer from "./components/footer";
import AboutPage from "./components/aboutPage";
import ServicePage from "./components/servicePage";
import { Route, Routes } from "react-router-dom";
import ContactForm from "./components/contactForm";

class App extends Component {
  state = {
    city: "Columbia",
    state: "Missouri",
    niche: "Tree Service",
    initials: "MO",
    phoneNumber: "5735558787",
  };
  render() {
    const { city, niche, state, phoneNumber, initials } = this.state;
    return (
      <div className="App">
        <Navigation city={city} niche={niche} />
        <Routes>
          <Route path="/services" element={<ServicePage />} />
          <Route path="/contact" element={<ContactForm />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/" element={<LandingPage />} />
        </Routes>
        {/* <LandingPage
          phone={phoneNumber}
          city={city}
          state={initials}
          niche={niche}
        /> */}

        <Footer
          city={city}
          state={state}
          niche={niche}
          phoneNumber={phoneNumber}
        />
      </div>
    );
  }
}
export default App;
