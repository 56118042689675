import React, { Component } from "react";
import { Link } from "react-router-dom";

class Navigation extends Component {
  state = {
    toggle: false,
  };
  toggleNav = () => {
    const toggle = !this.state.toggle;
    this.setState({ toggle });
  };
  render() {
    const { city, niche } = this.props;
    const { toggle } = this.state;
    return (
      <header className="absolute top-0 left-0 w-full z-50 px-4 shadow-sm sm:px-8 lg:px-16 xl:px-40 2xl:px-64 bg-white">
        <div className="flex flex-wrap items-center justify-between py-6">
          <div className=" md:w-auto">
            <Link to="/" className="text-gray-800 font-bold text-2xl">
              {city} {niche}
            </Link>
          </div>

          <div>
            <button
              id="nav_toggle"
              className="h-8 w-8 md:hidden open"
              onClick={this.toggleNav}
            >
              <svg
                className="h-full w-auto"
                xmlns="http://www.w3.org/2000/svg"
                width="192"
                height="192"
                fill="black"
                viewBox="0 0 256 256"
              >
                <rect width="256" height="256" fill="none"></rect>
                <line
                  x1="40"
                  y1="128"
                  x2="216"
                  y2="128"
                  stroke="black"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="16"
                ></line>
                <line
                  x1="40"
                  y1="64"
                  x2="216"
                  y2="64"
                  stroke="black"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="16"
                ></line>
                <line
                  x1="40"
                  y1="192"
                  x2="216"
                  y2="192"
                  stroke="black"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="16"
                ></line>
              </svg>
            </button>

            <div
              id="nav_content"
              className={
                toggle
                  ? "absolute right-0  md:block md:relative w-full md:w-auto "
                  : "absolute right-0 hidden  md:block md:relative w-full md:w-auto "
              }
            >
              <nav className="w-full bg-white md:bg-transparent rounded shadow-lg px-6 py-4 mt-4 text-center md:p-0 md:mt-0 md:shadow-none">
                <ul className="md:flex items-center">
                  <li>
                    <Link
                      className="py-2 inline-block md:text-gray-700 md:hidden lg:block font-semibold hover:underline"
                      to="/about"
                    >
                      About Us
                    </Link>
                  </li>
                  <li className="md:ml-4">
                    <Link
                      className="py-2 inline-block md:text-gray-700 md:px-2 font-semibold hover:underline"
                      to="/services"
                    >
                      Services
                    </Link>
                  </li>
                  <li className="md:ml-5 mt-3 md:mt-0">
                    <Link
                      className="inline-block font-semibold px-4 py-2 text-gray-50 bg-green-400 md:bg-transparent md:text-gray-700 border-gray-700 rounded hover:bg-green-400 hover:text-green-50 hover:border-white"
                      to="/contact"
                    >
                      Contact Us
                    </Link>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </header>
    );
  }
}
export default Navigation;
