import React, { Component } from "react";
const Input = ({ name, label, value, onChange, pattern, type, error }) => {
  return (
    <>
      <div className="input-animated">
        <input
          value={value}
          onChange={onChange}
          type={type}
          id={name}
          name={name}
          required
          placeholder="the placeholder"
        />
        <label htmlFor={name} className="label-name">
          <span className="content-name">{label}</span>
        </label>
      </div>
      {error && (label == "Phone" && value.length > 10 ? true : false) && (
        <div className=" px-10 mb-2 -mt-3 error-field">{error}</div>
      )}
    </>
  );
};

export default Input;
